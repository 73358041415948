<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <Spinner v-if="isPageLoading" />

      <b-tabs v-if="!isPageLoading">
        <b-tab
          v-for="fr in fractions"
          v-bind:key="fr.title"
          v-bind:title="fr.title + ' (' + fr.places_total + ')'"
          :active="selectedFractionId === fr.id"
          :disabled="fr.is_empty"
          @click="selectFraction(fr.id)"
        >
          <div class="p-1 p-md-3 d-flex flex-wrap justify-content-center">
            <b-card
              no-body
              v-for="member in members[selectedFractionId]"
              v-bind:key="member.Code"
              class="m-1 m-md-2 p-1 p-md-3 d-flex flex-sm-column flex-md-row align-items-center justify-content-between"
              style="max-width: 25rem;"
            >
              <div class="text-center">
                <b-card-img
                  :src="member.PassportPhoto.Path"
                  :alt="member.PassportPhoto.Name"
                  style="height: 133px; width: 100px"
                />
                <h4 class="m-0">
                  <span class="badge badge-secondary">
                    {{ member.Code }}
                  </span>
                </h4>
              </div>
              <b-row class="pt-3 pt-md-0">
                <b-col
                  class="text-right d-flex flex-column justify-content-between"
                >
                  <p>Позывной:</p>
                  <p>Костюм:</p>
                  <p>Ранг:</p>
                </b-col>
                <b-col>
                  <p>{{ member.Callsign }}</p>
                  <p>{{ member.Suit.Title }}</p>
                  <p>{{ getRank(member.Rank) }}</p>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import API from "../../api/stalk.net";
import Spinner from "../../components/common/Spinner";

export default {
  name: "Members",
  components: {
    Spinner
  },
  data() {
    return {
      isPageLoading: false,
      baseURL: API.baseURL,
      fractions: [],
      selectedFractionId: 0,
      members: []
    };
  },
  methods: {
    selectFraction(id) {
      let self = this;

      self.selectedFractionId = id;
      if (self.members[self.selectedFractionId]) return;

      self.isPageLoading = true;
      API.private
        .getMembersByEvent(self.$route.params.id, {
          onlyMembers: true,
          fractionId: self.selectedFractionId
        })
        .then(resp => {
          self.members[self.selectedFractionId] = resp.data; //fill the selected fraction
          self.isPageLoading = false;
        })
        .catch(self.catchError);
    },
    loadData() {
      let self = this;

      self.isPageLoading = true;
      API.public
        .loadFractions(true)
        .then(resp => {
          if (resp.data.length === 0) return Promise.resolve(); //no data to load
          self.fractions = resp.data;
          // select 1st active
          self.selectedFractionId = self.fractions.find(f => !f.is_empty).id;

          return API.private.getMembersByEvent(self.$route.params.id, {
            onlyMembers: true,
            fractionId: self.selectedFractionId
          });
        })
        .then(resp => {
          self.members[self.selectedFractionId] = resp.data; //fill the selected fraction
          self.isPageLoading = false;
        })
        .catch(self.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (
        e.response &&
        e.response.data &&
        e.response.data.error
      ) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    },
    getRank(rank) {
      return API.getRankTitle(rank);
    }
  },
  computed: {},
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
